
export default {
  data: function(){
    return {
      form: {
        code: ''
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
      const inputCode = (this as any).form.code
      if(typeof inputCode === 'undefined' || inputCode == null || inputCode === ''){
        (this as any).$alert('请输入条码','提醒',{ confirmButtonText : '确定' })
      } else {
        (this as any).$router.push({ name: 'result',params: { code: inputCode } })
      }
    }
  }
}
